akwaaba-forms-login,
akwaaba-forms-public-login,
akwaaba-forms-forgot-password,
akwaaba-forms-public-forgot-password,
akwaaba-forms-reset-password,
akwaaba-forms-public-reset-password {
    display: block;
}


/* START :: VARIABLES */

@import '../../variables/variables.scss';

/* END :: VARIABLES */


/* START :: TAILWIND CSS */

@import '../tailwind-output/login.scss';

/* END :: TAILWIND CSS */